import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/beer-factory-blog/beer-factory-blog/src/templates/blog-post.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Aujourd'hui je vous propose un premier article de test. Nous parlerons du Airlock de Plaato.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Cet article n'est pas sponsorisé et n'engage que moi. Il est le reflet d'un usage sur un peu plus d'un an sur une dizaine de brassin.`}</p>
    </blockquote>
    <h1>{`Pour la fermentation`}</h1>
    <p><a parentName="p" {...{
        "href": "https://www.programmingelectronics.com/homebrewing-arduino-student-project/"
      }}>{`https://www.programmingelectronics.com/homebrewing-arduino-student-project/`}</a>{`
`}<a parentName="p" {...{
        "href": "https://www.sparkfun.com/tutorials/131"
      }}>{`https://www.sparkfun.com/tutorials/131`}</a>{`
`}<a parentName="p" {...{
        "href": "https://www.instructables.com/id/Bubble-Catcher-Part-1-of-2/"
      }}>{`https://www.instructables.com/id/Bubble-Catcher-Part-1-of-2/`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.brassageamateur.com/wiki/Densim%C3%A8tre_Wifi_iSpindle"
      }}>{`https://www.brassageamateur.com/wiki/Densim%C3%A8tre_Wifi_iSpindle`}</a></p>
    <h1>{`Pour le brassage`}</h1>
    <p><a parentName="p" {...{
        "href": "http://web.craftbeerpi.com/"
      }}>{`http://web.craftbeerpi.com/`}</a></p>
    <h1>{`Conclusion`}</h1>
    <p>{`Vous l'aurez compris je ne vous recommande pas forcément ce produit.`}</p>
    <p>{`Le principe est intéresant mais n'est pas suffisamment qualitatif pour y mettre le prix demandé. Pour le moment mon exemplaire est toujours fonctionnel et me donne encore satisfaction mais pour combien de brassin encore ?`}</p>
    <p>{`Leur second produit, le platoo Keg semble souffrir des même type de défaut raison pour laquelle je n'ai pas craqué (et que l'intégraton n'est pas disponible sur `}<a parentName="p" {...{
        "href": "https://mybeerfactory.fr"
      }}>{`My Beer Factory`}</a>{`)
Espérons que la jeune entreprise norvégienne nous propose une seconde version plus qualitative.`}</p>
    <p>{`N'hésitez pas à me faire part de vos remarques, idées d'amélioration ou simplement à partager vos créations en commentaire ou sur le `}<a parentName="p" {...{
        "href": "https://www.facebook.com/beerFactoryApp"
      }}>{`groupe facebook`}</a>{`. Cet article étant le premier je suis entrée dans les détails mais au fur et à mesure j'ai prévu de rester plus succinct qu'en pensez vous ?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      